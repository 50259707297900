<template>    
  <div>
    <b-input-group size="sm" :prepend="getCurrencySymbol">
      <b-form-input v-model="crud.form.total" 
                    type="number"
                    step="0.01"                    
                    :readonly="true"
                    class="input-chqeues-total"
                    @change="openCrud()">
      </b-form-input>      

      <b-input-group-append>          
        <b-button variant="outline-info" 
                  @click="openCrud()" 
                  title="Cargar el detalle de los valores recibidos"
                  class="button-cheques-open">
          Valores
          <b-icon icon="chevron-double-right"></b-icon>
        </b-button>
      </b-input-group-append>                         
    </b-input-group>

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- CRUD MAIN -->  
    <b-modal v-model="modal.form.active"
            header-bg-variant="dark"
            header-text-variant="white">

      <div slot="modal-header">
        <Minimize />
        {{this.modal.form.title}}
      </div>
      
      <b-row :key="keyForceUpdate">
        <b-col>
          <b-row v-if="type=='ADD'">
            <b-col md="10">
              <b-row>
                <b-col md="6" class="pr-0">              
                  <b-form-group label="Número">
                    <b-input-group size="sm">
                      <b-form-input type="number"                                
                                    v-model="crud.formTMP.number"       
                                    @change="openCrudCheque()"                       
                                    placeholder="Número Cheque">
                      </b-form-input>
                      <b-input-group-append>          
                        <b-button variant="outline-success" 
                                  title="Cargar mas info sobre el cheque"
                                  @click="openCrudCheque()">                      
                          <b-icon icon="three-dots"></b-icon>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>                    
                </b-col>             

                <b-col md="6" class="pl-1 pr-1">
                  <b-form-group label="Importe">
                    <b-input-group size="sm" prepend="$" append=".00">
                      <b-form-input type="number"
                                    step="0.01"
                                    size="sm"
                                    v-model="crud.formTMP.total"
                                    placeholder="Ingrese un importe">
                      </b-form-input>              
                    </b-input-group>
                  </b-form-group>                
                </b-col>
              </b-row>
            </b-col>

            <b-col md="2">
              <b-button variant="success" @click="add()" size="sm" title="Agregar" class="crud-chas-add pull-right">
                <b-icon icon="plus" aria-hidden="true"></b-icon>
              </b-button> 
            </b-col>
          </b-row>
          <b-row v-if="type=='SUB'">
            <b-col md="12">
              <b-button variant="outline-info" @click="openSearchCheque()" size="sm" title="Buscar Valores" class="pull-right w-100">
                <b-icon icon="search" aria-hidden="true"></b-icon> Buscar Valores
              </b-button>               
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>              
        <b-col>              
          <b-row class="mt-2">
            <b-col md="12">                                                  
              <b-table  class="mb-0 table-cash-sub"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"                            
                        v-if="table.items.length">   
                
                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template>            

                <template v-slot:cell(id)="data">
                  <div v-if="type=='SUB'">
                    {{data.item.id}}
                  </div>
                </template>

                <template v-slot:cell(number)="data">
                  {{data.item.number}}
                </template>
                
                <template v-slot:cell(date_expiration)="data">
                  {{moment(data.item.date_expiration).format('DD/MM/YYYY')}}
                </template>                

                <template v-slot:cell(importe)="data">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(data.item.total)}}                  
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right 
                              text="Acción" 
                              size="sm" 
                              variant="outline-dark" 
                              class="pull-right" >
                                                                      
                    <b-dropdown-header>Acciones</b-dropdown-header>                          
                    <b-dropdown-item @click="edit(data.item, data.index)" v-if="type=='ADD'">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>                    
                    <b-dropdown-item @click="remove(data.index)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>                      
              <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
            </b-col>                    
          </b-row> 
        </b-col>              
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
        <b-button variant="dark" @click="save()">Guardar</b-button>          
      </div>

    </b-modal>

    <!-- CRUD CHEQUE -->
    <b-modal v-model="modal.cheque.active"
            header-bg-variant="dark"
            header-text-variant="white">

      <div slot="modal-header">
        <Minimize />
        {{this.modal.cheque.title}}
      </div>

      <b-row>
        <b-col md="6">
          <b-form-group label="Número">
            <b-form-input type="number"
                          size="sm"
                          v-model="crud.formTMP.number"
                          required
                          placeholder="Número de Cheque">
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Importe">
            <b-input-group prepend="$" size="sm">            
              <b-form-input v-model="crud.formTMP.total" 
                            type="number" 
                            setp="0.01" 
                            min="0.00">
              </b-form-input>            
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Fecha">
            <b-form-datepicker size="sm" 
                               v-model="crud.formTMP.date" 
                               placeholder="Fecha" 
                               local="es">
            </b-form-datepicker>                      
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Vencimiento">
            <b-form-datepicker size="sm" 
                               v-model="crud.formTMP.date_expiration" 
                               placeholder="Fecha Vencimiento" 
                               local="es">
            </b-form-datepicker>                      
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group label="Banco">
            <b-form-input type="text"
                          size="sm"
                          v-model="crud.formTMP.bank"                          
                          placeholder="Ingresar el banco">
            </b-form-input>
          </b-form-group>
        </b-col>
        
        <b-col md="6">
          <b-form-group label="Nombre Emisor">
            <b-form-input type="text"
                          size="sm"
                          v-model="crud.formTMP.transmitter_name"                          
                          placeholder="Ingresar el nombre del emisor">
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="CUIT Emisor">
            <b-form-input type="text"
                          size="sm"
                          v-model="crud.formTMP.transmitter_documents_number"                          
                          placeholder="Ingresar el CUIT del emisor">
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group label="Observaciones">
            <b-form-textarea                
              v-model="crud.formTMP.observations"
              placeholder="Ingresar las observaciones..."
              rows="3"
              max-rows="6"></b-form-textarea>
          </b-form-group>
        </b-col>  
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" size="sm" class="mr-1" @click="modal.cheque.active=false">Cancelar</b-button>
        <b-button variant="dark" size="sm" @click="saveCheque()">Listo</b-button>          
      </div>

    </b-modal>    

    <!-- SEARCH CHEQUE -->
    <b-modal v-model="modal.search.active"
            header-bg-variant="dark"
            header-text-variant="white">

      <div slot="modal-header">
        <Minimize />
        {{this.modal.search.title}}
      </div>

      <b-row>
        <b-col md="12">                                                  
          <b-table  class="mb-0 table-cash-sub"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="tableCheque.items"
                    :fields="tableCheque.fields"                            
                    v-if="tableCheque.items.length">   
            
            <template v-slot:table-colgroup="scope">
              <col
                v-for="field in scope.fields"                    
                :key="field.key"
                :style="{ width: field.width }"
              >
            </template>            
                                    
            <template v-slot:cell(number)="data">
              {{data.item.number}}
            </template>
            
            <template v-slot:cell(date_expiration)="data">
              {{moment(data.item.date_expiration).format('DD/MM/YYYY')}}
            </template>                

            <template v-slot:cell(importe)="data">
              {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(data.item.amount)}}                  
            </template>

            <template v-slot:cell(f_action)="data">
              <b-button variant="info" size="sm" class="mr-1" @click="addCheque(data.item)">Seleccionar</b-button>              
            </template>
          </b-table>
          <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
        </b-col>
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" size="sm" class="mr-1" @click="modal.search.active=false">Cancelar</b-button>        
      </div>

    </b-modal>        
  </div>
</template>
<script>  
  import serviceAPI from './../services'
  import ErrorToken from '@/handler/errorToken'
  import Helper from '@/handler/helper'
  import FindObject from '@/components/inc/find/findObject'  
  import moment from 'moment'
  import Minimize from '@/components/inc/modal/minimize'

  export default {
    props: {
      reset: {
        type: Boolean,
        default: false
      },
      points_sales: {
        type: Object,
        default: null,
      },     
      type: {
        type: String,
        default: 'ADD',
      },                               
    },    
    components: {
      FindObject,      
      Minimize,
    },    
    data: () => {
      return { 
        crud: {
          form: {
            total: 0,          
            detail: [],
          },
          formTMP: {
            id: 0,
            number: '',
            date: '',
            date_expiration: '',
            bank: '',
            transmitter_name: '',
            transmitter_documents_number: '',
            observations: '',
            total: 0,
            nd: false,
            accounting_account_default: null,
          },
        },     
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', class: "align-middle text-center font-weight-bold", width:"5%"},
            {key: 'number', label: 'Número', class:"align-middle text-left", width:"20%"},
            {key: 'date_expiration', label: 'Vencimiento', class:"align-middle text-left", width:"15%"},                        
            {key: 'importe', label: 'Importe', class:"align-middle text-right", width:"20%"},       
            {key: 'f_action', label: '', class:"align-middle text-center", width:"20%"}        
          ]
        },        
        tableCheque : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', class: "align-middle text-center font-weight-bold", width:"5%"},
            {key: 'number', label: 'Número', class:"align-middle text-left", width:"20%"},
            {key: 'date_expiration', label: 'Vencimiento', class:"align-middle text-left", width:"15%"},                        
            {key: 'importe', label: 'Importe', class:"align-middle text-right", width:"20%"},       
            {key: 'f_action', label: '', class:"align-middle text-center", width:"20%"}        
          ]
        },                     
        modal: {
          form: {
            active: false,
            title: ''
          }, 
          cheque: {
            active: false,
            title: ''
          }, 
          search: {
            active: false,
            title: ''
          },                              
        },   
        arr: {
          cheques: []
        },
        keyForceUpdate: 0,    
        accounting_account: null,
      }
    }, 
    computed: {
      // CURRENCY
      getCurrencySymbol() {
        return '$'
      },
      getCurrencyCode() {
        return 'ARS'
      }      
    },
    watch: {        
      'reset': function (newQuestion, oldQuestion) {    
        if(newQuestion == true) {          
          this.crud.form = {
            total: 0,          
            detail: [],       
          },
          
          this.table.items = []

          this.$emit('getFinancesCheques', this.crud.form)      
        }
      },
    },     
    mounted() {
      if(this.type == 'ADD') {
        this.loadAccountingAccount()
      }      
    },   
    methods: {          
      // CRUD
      openCrud() {      
        this.crud.formTMP.total = this.crud.form.total        
        this.modal.form.title = "Detalle Valores"
        this.modal.form.active = true        
      },
      edit(item, index) {
        this.crud.formTMP.id = 0
        this.crud.formTMP.number = item.number
        this.crud.formTMP.date = item.date
        this.crud.formTMP.date_expiration = item.date_expiration
        this.crud.formTMP.bank = item.bank
        this.crud.formTMP.transmitter_name = item.transmitter_name
        this.crud.formTMP.transmitter_documents_number = item.transmitter_documents_number
        this.crud.formTMP.observations = item.observations
        this.crud.formTMP.total = item.total
        this.crud.formTMP.nd = item.nd
                             
        this.crud.form.detail.splice(index, 1)        
      },
      add() {
        // NUMERO SIN CARGAR
        if(!this.crud.formTMP.number) {
          this.$awn.alert("No puede carga un item sin número");
          return false          
        }       

        // NO SE PUEDE GUARDAR MAS DE UNA VEZ EL MISMO CHEQUE
        var existe = false
        if(this.crud.form.detail.length) {
          this.crud.form.detail.forEach(element => {
            if(element.number == this.crud.formTMP.number) {              
              existe = true
            }
          });
        }
        if(existe) {
          this.$awn.alert("Ya existe un item con el mismo número");
          return false
        }        

        // TOTAL MAYOR A CERO
        if(!parseFloat(this.crud.formTMP.total)) {
          this.$awn.alert("No puede agregar el total en cero");
          return false          
        }       

        // FECHAS
        if(!this.crud.formTMP.date || !this.crud.formTMP.date_expiration) {
          this.$awn.alert("La fecha de emision y vencimiento tienen que estar cargadas");
          return false          
        }     

        this.crud.form.detail.push({
          id: 0,
          number: this.crud.formTMP.number,
          date: this.crud.formTMP.date,
          date_expiration: this.crud.formTMP.date_expiration,
          bank: this.crud.formTMP.bank,
          transmitter_name: this.crud.formTMP.transmitter_name,
          transmitter_documents_number: this.crud.formTMP.transmitter_documents_number,
          observations: this.crud.formTMP.observations,
          total: this.crud.formTMP.total,    
          nd: this.points_sales.nd,
          accounting_account_default: this.accounting_account,
        })

        this.table.items = this.crud.form.detail 
        this.clear()
      },  
      save() {
        var sum = 0
        if(this.crud.form.detail) {                    
          this.crud.form.detail.forEach(element => {            
            sum = sum + parseFloat(element.total)
          });
        }
        this.crud.form.total = sum          
        this.$emit('getFinancesCheques', this.crud.form)      
        this.modal.form.active = false   
      },    
      remove(index) {
        this.crud.form.detail.splice(index, 1)
      },      
      clear() {             
        this.crud.formTMP.id = 0
        this.crud.formTMP.number = ''
        this.crud.formTMP.date = ''
        this.crud.formTMP.date_expiration = ''
        this.crud.formTMP.bank = ''
        this.crud.formTMP.transmitter_name = ''
        this.crud.formTMP.transmitter_documents_number = ''
        this.crud.formTMP.observations = ''
        this.crud.formTMP.total = 0
        this.crud.formTMP.nd = false
        this.forceUpdate()         
      },
      forceUpdate() {
        this.keyForceUpdate = this.keyForceUpdate + 1
      },      

      // CHEQUE
      openCrudCheque() {
        this.modal.cheque.title = "Información del Cheque"
        this.modal.cheque.active = true        
      },
      saveCheque() {
        if(this.crud.formTMP.number && this.crud.formTMP.total) {
          this.add()
        }
        this.modal.cheque.active = false
      },      

      // SEARCH
      openSearchCheque() {
        var result = serviceAPI.obtenerChequesByType({
          nd: this.points_sales.nd,          
        })

        result.then((response) => {
          var data = response.data
          this.arr.cheque = data
          this.tableCheque.items = data         
          
          this.tableCheque.items.forEach(element => {            
            if(element.date_expiration < moment().add(7, 'days').format('YYYY-MM-DD') && element.available) {
              element._rowVariant = 'warning'  
            }
            if(element.date_expiration > moment().add(7, 'days').format('YYYY-MM-DD') && element.available) {
              element._rowVariant = 'success'  
            }
          })          
        })                 
        
        this.modal.search.title = "Cartera de Valores"
        this.modal.search.active = true                
      },      
      addCheque(item) {              
        // NO SE PUEDE GUARDAR MAS DE UNA VEZ EL MISMO CHEQUE
        var existe = false        
        if(this.crud.form.detail.length) {
          this.crud.form.detail.forEach(element => {            
            if(element.id == item.id) {              
              existe = true
            }
          });
        }
        if(existe) {
          this.$awn.alert("El valor ya se encuentra seleccionado");
          return false
        }        

        this.crud.form.detail.push({
          id: item.id,
          number: item.number,
          date: item.date,
          date_expiration: item.date_expiration,
          bank: item.bank,
          transmitter_name: item.transmitter_name,
          transmitter_documents_number: item.transmitter_documents_number,
          observations: item.observations,
          total: item.amount,    
          nd: item.nd,
          accounting_account_default: item.accounting_account_default,
        })        

        this.table.items = this.crud.form.detail 
        this.clear()       
                
        this.modal.search.active = false
      },

      // LOAD ACCOUNTING
      loadAccountingAccount() {
        var result = serviceAPI.obtenerAccountingAccountCheque({
          reference: 'valores',
        })

        result.then((response) => {
          var data = response.data          

          this.accounting_account = data.accounting_account
        })
      }
    }
  }
</script>
<style>
  .crud-chas-add {
    margin-top: 30px;
  }
  .table-cash-sub {
    min-height: 100px;
  }
  .button-cheques-open {
    min-width: 135px;
    font-weight: 800;
    text-align: right;
  }
  .input-chqeues-total {
    font-weight: 800;
  }   
</style>