<template>    
  <div>
    <b-input-group size="sm" :prepend="getCurrencySymbol">
      <b-form-input v-model="crud.form.total" 
                    type="number"
                    step="0.01"                    
                    :readonly="true"
                    class="input-cash-total"
                    @change="openCrud()">
      </b-form-input>      

      <b-input-group-append>          
        <b-button variant="outline-info" 
                  @click="openCrud()"
                  title="Cargar el detalle del importe recibido por cada medios de pago"
                  class="button-cash-open">
          Importes
          <b-icon icon="chevron-double-right"></b-icon>
        </b-button>
      </b-input-group-append>                         
    </b-input-group>

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <!-- CRUD MAIN -->  
    <b-modal v-model="modal.form.active"
            header-bg-variant="dark"
            header-text-variant="white">

      <div slot="modal-header">
        <Minimize />
        {{this.modal.form.title}}
      </div>
      
      <b-row :key="keyForceUpdate">
        <b-col md="10">
          <b-row>
            <b-col md="7" class="pr-0">
              <FindObject type="cash" 
                          tag="Caja/Cuenta"
                          @select-object="loadCash($event)" 
                          :valueID="crud.formTMP.cash_id"
                          :where="conditionCash"
                          render="search"                          
                          :showAllRegister="true"/>                  
            </b-col>              
            <b-col md="5" class="pl-1 pr-1">
              <b-form-group label="Importe">
                <b-input-group size="sm" prepend="$" append=".00">
                  <b-form-input type="number"
                                step="0.01"
                                size="sm"
                                min="0"
                                v-model="crud.formTMP.total"
                                placeholder="Ingrese un importe">
                  </b-form-input>              
                </b-input-group>
              </b-form-group>                
            </b-col>
          </b-row>
        </b-col>

        <b-col md="2">
          <b-button variant="success" @click="add()" size="sm" title="Agregar" class="crud-chas-add pull-right">
            <b-icon icon="plus" aria-hidden="true"></b-icon>
          </b-button> 
        </b-col>

        <b-col md="12" v-if="crud.formTMP.cash && type=='SUB'">
          <b-alert variant="success" v-if="crud.formTMP.cash.total_available_ars>0" show>
            <b>DISPONIBLE EN: {{this.crud.formTMP.cash.name}}":</b> {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(this.crud.formTMP.cash.total_available_ars)}}            
          </b-alert>          
          <b-alert variant="danger" v-if="crud.formTMP.cash.total_available_ars<0 && crud.formTMP.cash.uncovered" show>
            <b>EN DESCUBIERTO: {{this.crud.formTMP.cash.name}}":</b> {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(this.crud.formTMP.cash.total_available_ars)}}            
          </b-alert>          
          <b-alert variant="danger" v-if="crud.formTMP.cash.total_available_ars<0 && !crud.formTMP.cash.uncovered" show>
            <b>SIN SALDO</b>
          </b-alert>                    
        </b-col>        
      </b-row>
      <b-row class="mt-2">              
        <b-col>              
          <b-row>
            <b-col md="12">                                                  
              <b-table  class="mb-0 table-cash-sub"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"                            
                        v-if="table.items.length">   
                
                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template>            
                                                
                <template v-slot:cell(cash)="data">
                  {{data.item.cash.name}}
                </template>
                
                <template v-slot:cell(importe)="data">
                  {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(data.item.total)}}                  
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right 
                              text="Acción" 
                              size="sm" 
                              variant="outline-dark" 
                              class="pull-right" >
                                                                      
                    <b-dropdown-header>Acciones</b-dropdown-header>     
                    <b-dropdown-item @click="edit(data.item, data.index)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>                                              
                    <b-dropdown-item @click="remove(data.index)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>                      
              <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
            </b-col>                    
          </b-row> 
        </b-col>              
      </b-row>

      <div slot="modal-footer">
        <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
        <b-button variant="dark" @click="save()">Guardar</b-button>          
      </div>

    </b-modal>
  </div>
</template>
<script>  
  import serviceAPI from './../services'
  import ErrorToken from '@/handler/errorToken'
  import Helper from '@/handler/helper'
  import FindObject from '@/components/inc/find/findObject'  
  import Param from '@/config/parameters'
  import Minimize from '@/components/inc/modal/minimize'

  export default {
    props: {
      reset: {
        type: Boolean,
        default: false
      },
      points_sales: {
        type: Object,
        default: null,
      },       
      type: {
        type: String,
        default: 'ADD',
      },   
      amount_pending: {
        type: Number,
        default: 0,
      }
    },
    components: {
      FindObject,      
      Minimize,
    },    
    data: () => {
      return { 
        crud: {
          form: {
            total: 0,          
            detail: [],
          },
          formTMP: {
            cash_id: 0,
            cash: null,
            total: 0,
          },
        },     
        table : {
          items: [],
          fields: [
            {key: 'cash', label: 'Caja/Cuenta', class:"align-middle text-left", width:"40%"},
            {key: 'importe', label: 'Importe', class:"align-middle text-right", width:"40%"},       
            {key: 'f_action', label: '', class:"align-middle text-center", width:"20%"}        
          ]
        },             
        modal: {
          form: {
            active: false,
            title: ''
          },          
        },   
        object: {
          staff: null
        },
        keyForceUpdate: 0, 
        parameters: {
          freeAddTypeOperation: false
        }       
      }
    }, 
    computed: {
      // CONDITIONS SELECT
      conditionCash(){        
        var arrWhere = []
        if(!this.parameters.freeAddTypeOperation) {
          arrWhere.push({field: 'nd', condition: this.points_sales.nd})
        }         

        if(this.object.staff && this.object.staff.cash_id) {
          arrWhere.push({field: 'id', condition: this.object.staff.cash_id})
        } else {
          if(this.points_sales.cash_id) {
            arrWhere.push({field: 'id', condition: this.points_sales.cash_id})
          }
        }
        return arrWhere;
      },        
      // CURRENCY
      getCurrencySymbol() {
        return '$'
      },
      getCurrencyCode() {
        return 'ARS'
      }      
    },
    watch: {        
      'reset': function (newQuestion, oldQuestion) {    
        if(newQuestion == true) {          
          this.crud.form = {
            total: 0,          
            detail: [],       
          },

          this.table.items = []
          
          this.$emit('getFinancesCash', this.crud.form)     
        }
      },
      'amount_pending': function (newQuestion, oldQuestion) {         
        if(newQuestion) {  
          this.crud.formTMP.total = Math.abs(this.amount_pending)        
        }   
      }
    },        
    mounted() {
      this.parameters.freeAddTypeOperation = Helper.hasParametersAccess(Param.P61)

      this.showStaff()      
      this.searchCash()
    },
    methods: {        
      // CRUD   
      searchCash() {        
        var result = serviceAPI.obtenerCajas()

        result.then((response) => {
          var data = response.data
          
          if(data.length) {              
            this.crud.formTMP.cash = data[0]
            this.crud.formTMP.cash_id = data[0].id
            this.forceUpdate()
          }      
          

          if(this.object.staff && this.object.staff.cash_id) {
            this.crud.formTMP.cash_id = this.object.staff.cash_id
            this.forceUpdate()
          } else {
            if(this.points_sales.cash_id) {
              this.crud.formTMP.cash_id = this.points_sales.cash_id
              this.forceUpdate()
            }
          }
        })                  
      },
      openCrud() {      
        this.modal.form.title = "Detalle de importe por medios de pago"
        this.modal.form.active = true        
      },
      edit(item, index) {
        this.crud.formTMP.cash_id = item.cash_id
        this.crud.formTMP.cash = item.cash  
        this.crud.formTMP.total = item.total
                             
        this.crud.form.detail.splice(index, 1)       
        this.forceUpdate()
      },      
      add() {
        // CAJA/CUENTA SIN CARGAR
        if(!parseFloat(this.crud.formTMP.cash_id)) {
          this.$awn.alert("No puede cargar un item sin caja/cuenta");
          return false          
        }       

        // NO SE PUEDE GUARDAR MAS DE UNA VEZ LA MISMA CAJA/CUENTA
        var existe = false
        if(this.crud.form.detail.length) {
          this.crud.form.detail.forEach(element => {
            if(element.cash_id == this.crud.formTMP.cash_id) {              
              existe = true
            }
          });
        }
        if(existe) {
          this.$awn.alert("Ya existe un item con la misma caja/cuenta");
          return false
        }        

        // TOTAL MAYOR A CERO
        if(parseFloat(this.crud.formTMP.total) <= 0) {
          this.$awn.alert("No puede agregar el total en cero o menor a cero");
          return false          
        }       

        // SI ES UN PAGO
        if(this.type == 'SUB') {
          if(!this.crud.formTMP.cash.uncovered) {          
            if(parseFloat(this.crud.formTMP.cash.total_available_ars) < parseFloat(this.crud.formTMP.total)) {
              this.$awn.alert("No puede emitir un pago por un total mayor al disponible en caja/cuenta");
              return false                      
            }
          }
        }

        this.crud.form.detail.push({
          cash: this.crud.formTMP.cash,
          cash_id: this.crud.formTMP.cash_id,
          total: this.crud.formTMP.total,            
        })

        this.table.items = this.crud.form.detail 
        this.clear()
      },  
      save() {
        var sum = 0
        if(this.crud.form.detail) {                    
          this.crud.form.detail.forEach(element => {            
            sum = sum + parseFloat(element.total)
          });
        }
        this.crud.form.total = sum          
        this.$emit('getFinancesCash', this.crud.form)      
        this.modal.form.active = false   
      },    
      remove(index) {
        this.crud.form.detail.splice(index, 1)
      },      
      clear() {      
        this.crud.formTMP.cash_id = 0
        this.crud.formTMP.cash = null
        this.crud.formTMP.total = 0         
        this.forceUpdate()         
      },
      forceUpdate() {
        this.keyForceUpdate = this.keyForceUpdate + 1
      },     
      loadCash(object){
        if(object){
          this.crud.formTMP.cash = object
          this.crud.formTMP.cash_id = object.id
        } else {
          this.crud.formTMP.cash = null
          this.crud.formTMP.cash_id = 0
        }
      },       
      showStaff()  {
        let staffID = Helper.getStaffId()
        var result = serviceAPI.mostrarStaff(staffID)

        result.then((response) => {
          var data = response.data
          if(data) {
            this.object.staff = data
          } else {
            this.object.staff = null
          }                     
        })          
      }
    }
  }
</script>
<style>
  .crud-chas-add {
    margin-top: 30px;
  }
  .table-cash-sub {
    min-height: 100px;
  }
  .button-cash-open {
    min-width: 135px;
    font-weight: 800;
    text-align: right;
  }     
  .input-cash-total {
    font-weight: 800;
  } 
</style>